import React from "react";
import axios, { Axios, AxiosError } from 'axios';
import { hostname } from "os";

const basePrefix: string = "/api"
const authenticateURL: string = "/authenticate"
const generateDeviceTokenURL: string = "/generate-device-token"
const registerURL: string = "/register"
const subscribeURL: string = "/subscribe"
const historyURL: string = "/weight/history"
const reportURL: string = "/weight/report"
const sessionHistoryURL: string = "/passives/history"
const quickWeighHistoryURL: string = "/quickweigh/history"
const myDeviceInfoURL: string = "/devices/info"

const generateDeviceTokenPermission: number = 1 << 3

type AuthenticateResponse = {
    errorCode: Number
    errorMessage: string
    username: string
    firstName: string
    lastName: string
    token: string
    permissions: [string]
    regiseredForNotifications: boolean
}

type Measurement = {
    date: Date
    weight: Number
}

type Session = {
    created: Date
    updated: Date
    startTime: Date
    duration: Number
    size: Number
};

export type HistoryResponse = {
    errorCode: Number
    errorMessage: string
    measurements: [Measurement]
}

export type SessionHistoryResponse = {
    errorCode: Number
    errorMessage: string
    sessions: [Session]
}

export type ReportWeightResponse = {
    errorCode: Number
    errorMessage: string
    weight: Number
}

export type GenerateDeviceTokenResponse = {
    error: boolean
    errorCode: Number
    errorMessage: string
    token: string
}

export type SoftwareVersionResponse = {
    version: string
    releaseNotes: string
}

export type DeviceInfoResponse = {
    errorCode: Number
    errorMessage: string
    serialNumber: string
    softwareVersion: SoftwareVersionResponse
    lastSeen: Date
}

export type QuickWeighResponse = {
    date: Date
    weight: Number
}

export type QuickWeighHistoryResponse = {
    errorCode: Number
    errorMessage: string
    quickWeighs: [QuickWeighResponse]

}

const buildURL = (endpoint: string) : string => {
    // var baseURL = "http://localhost:8888"
    // const url = new URL(window.location.href)
    // if (url.hostname != "localhost") {
    //     baseURL = url.protocol + "//" + url.host
    // }

    return basePrefix + endpoint
}

export class PassiveAPI {

    private static usernameKey: string = "passiveUsername"
    private static firstNameKey: string = "passiveFirstName"
    private static lastNameKey: string = "passiveLastName"
    private static tokenKey: string = "passiveAuthToken"
    private static permissionsKey: string = "passivePermissions"
    private static registeredForNotificationsKey: string = "passiveRegisteredForNotifications"

    static isAuthenticated() : boolean {
        console.log("isAuthenticated invoked");
        let token = localStorage.getItem(this.tokenKey)
        if (token?.length) {
            console.log("isAuthenticated invoked returning true");
            return true
        }

        console.log("isAuthenticated invoked returning false");
        return false
    }

    static username() : string | null {
        return localStorage.getItem(this.usernameKey)
    }

    static firstName(): string | null {
        return localStorage.getItem(this.firstNameKey)
    }

    static lastName(): string | null {
        return localStorage.getItem(this.lastNameKey)
    }

    static permissions(): number {
        let permissionsString = localStorage.getItem(this.permissionsKey)
        if (permissionsString?.length) {
            return parseInt(permissionsString)
        }

        return 0
    }

    static setRegisteredForNotifications(registered: boolean) {
        localStorage.setItem(this.registeredForNotificationsKey, registered.toString())
    }

    static registeredForNotifications(): boolean {
        let registered = localStorage.getItem(this.registeredForNotificationsKey)
        if (registered?.length) {
            return registered == "true"
        }

        return false
    }

    static canGenerateDeviceTokens(): boolean {
        return (PassiveAPI.permissions() & generateDeviceTokenPermission) > 0
    }

    static async authenticate(username: string, password: string) : Promise<Error|null> {
        try {
            let endpointURL = buildURL(authenticateURL);
            console.debug("will send authenticate request with URL: " + endpointURL);

            const { data, status } = await axios.post<AuthenticateResponse>(
                endpointURL,
                {username: username, password: password},
                {
                    headers: {
                        'Content-Type': "application/json",
                        'Accept': "application/json"
                    }
                }
            )

            if (data.errorMessage) {
                return new Error(data.errorMessage);
            }

            localStorage.setItem(this.usernameKey, data.username);
            localStorage.setItem(this.firstNameKey, data.firstName);
            localStorage.setItem(this.lastNameKey, data.lastName);
            localStorage.setItem(this.tokenKey, data.token);
            localStorage.setItem(this.permissionsKey, JSON.stringify(data.permissions))
            localStorage.setItem(this.registeredForNotificationsKey, data.regiseredForNotifications.toString())

            return null;

        } catch (error) {
            console.error("error with authenticate request: " + JSON.stringify(error));
            return new Error("Unexpected error authenticating. Please try again.");
        }
    }

    static async generateDeviceToken(username: string) : Promise<GenerateDeviceTokenResponse> {
        try {
            let endpointURL = buildURL(generateDeviceTokenURL);
            console.debug("will send device token generation request with URL: " + endpointURL);

            const { data, status } = await axios.post<GenerateDeviceTokenResponse>(
                endpointURL,
                {username: username},
                {
                    headers: {
                        'Authorization': "Bearer " + localStorage.getItem(this.tokenKey),
                        'Content-Type': "application/json",
                        'Accept': "application/json"
                    }
                }
            )

            if (data.error) {
                throw new Error(data.errorMessage);
            }

            return data;

        } catch (error) {
            console.error("error with authenticate request: " + JSON.stringify(error));
            throw new Error("Unexpected error authenticating. Please try again.");
        }
    }

    static async register(code: string, username: string, firstName: string, lastName: string, password: string): Promise<AuthenticateResponse> {
        let endpointURL = buildURL(registerURL);
        console.debug("will send register request with URL: " + endpointURL);

        try {
            const { data, status } = await axios.post<AuthenticateResponse>(
                endpointURL,
                {code: code, username: username, firstName: firstName, lastName: lastName, password: password},
                {
                    headers: {
                        'Content-Type': "application/json",
                        'Accept': "application/json"
                    }
                }
            )

            if (data.errorMessage) {
                throw new Error(data.errorMessage);
            }
    
            localStorage.setItem(this.usernameKey, data.username);
            localStorage.setItem(this.tokenKey, data.token);
    
            return data;

        } catch (e: any) {
            if (e instanceof AxiosError) {
                console.error("error with register request: " + JSON.stringify(e));
                throw new Error("Unexpected error creating account. Please try again.")
            } else {
                throw new Error(e.message);
            }
        }
    }

    static async history(): Promise<HistoryResponse> {
        let endpoint = buildURL(historyURL)
        console.log("will send history request with URL: " + endpoint)

        const { data, status } = await axios.get<HistoryResponse>(endpoint,
            { headers: {'Authorization': "Bearer " + localStorage.getItem(this.tokenKey)}})
            
        if (data.errorMessage) {
            throw new Error(data.errorMessage);
        }

        return data
    }

    static async reportWeight(weight: Number) : Promise<ReportWeightResponse> {
        try {
            let endpointURL = buildURL(reportURL)
            console.debug("will send report request with URL: " + endpointURL);

            const { data, status } = await axios.post<ReportWeightResponse>(
                endpointURL,
                {weight: weight},
                {
                    headers: {
                        'Authorization': "Bearer " + localStorage.getItem(this.tokenKey),
                        'Content-Type': "application/json",
                        'Accept': "application/json"
                    }
                }
            )

            if (data.errorMessage) {
                throw new Error(data.errorMessage);
            }

            return data;

        } catch (error) {
            console.error("error with report weight request: " + JSON.stringify(error));
            throw new Error("Error reporting weight. Please try again.");
        }
    }

    static async sessionHistory(): Promise<SessionHistoryResponse> {
        try {
            let endpointURL = buildURL(sessionHistoryURL);
            console.debug("will send session history request with URL: " + endpointURL);

            const { data, status } = await axios.get<SessionHistoryResponse>(
                endpointURL,
                {
                    headers: {
                        'Authorization': "Bearer " + localStorage.getItem(this.tokenKey),
                        'Content-Type': "application/json",
                        'Accept': "application/json"
                    }
                }
            )

            if (data.errorMessage) {
                throw new Error(data.errorMessage);
            }

            return data;
        } catch (error) {
            console.error("error with session history request: " + JSON.stringify(error));
            throw new Error("Error fetching session history. Please try again.");
        }
    }

    static async myDeviceInfo(): Promise<DeviceInfoResponse> {
        try {
            let endpointURL = buildURL(myDeviceInfoURL);
            console.debug("will send my device info request with URL: " + endpointURL);

            const { data, status } = await axios.get<DeviceInfoResponse>(
                endpointURL,
                {
                    headers: {
                        'Authorization': "Bearer " + localStorage.getItem(this.tokenKey),
                        'Content-Type': "application/json",
                        'Accept': "application/json"
                    }
                }
            )

            if (data.errorMessage) {
                throw new Error(data.errorMessage);
            }

            return data;
        } catch (error) {
            console.error("error with my device info request: " + JSON.stringify(error));
            throw new Error("Error fetching device info. Please try again.");
        }
    }

    static async quickWeighHistory(): Promise<QuickWeighHistoryResponse> {
        try {
            let endpointURL = buildURL(quickWeighHistoryURL);
            console.debug("will send quick weigh history request with URL: " + endpointURL);

            const { data, status } = await axios.get<QuickWeighHistoryResponse>(
                endpointURL,
                {
                    headers: {
                        'Authorization': "Bearer " + localStorage.getItem(this.tokenKey),
                        'Content-Type': "application/json",
                        'Accept': "application/json"
                    }
                }
            )

            if (data.errorMessage) {
                throw new Error(data.errorMessage);
            }

            return data;
        } catch (error) {
            console.error("error with quick weigh history request: " + JSON.stringify(error));
            throw new Error("Error fetching quick weigh history. Please try again.");
        }
    }

    static async subscribeForNotifications(subscription: PushSubscription) {
        try {
            let endpointURL = buildURL(subscribeURL);
            console.debug("will send subscribe request with URL: " + endpointURL);

            const { data, status } = await axios.post(
                endpointURL,
                {subscription: subscription},
                {
                    headers: {
                        'Authorization': "Bearer " + localStorage.getItem(this.tokenKey),
                        'Content-Type': "application/json",
                        'Accept': "application/json"
                    }
                }
            )

            if (data.errorMessage) {
                throw new Error(data.errorMessage);
            }

        } catch (error) {
            console.error("error with notifications subscribe request: " + JSON.stringify(error));
            throw new Error("Unexpected error subscribing for notifications. Please try again.");
        }
    }
}