import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { PassiveAPI } from "../services/PassiveAPI";

export default function DeviceTokens() {

    let navigate = useNavigate();
    const [generating, setGenerating] = useState<boolean>(false);
    const [currentError, setCurrentError] = useState<Error|null>(null);
    const [lastGeneratedToken, setLastGeneratedToken] = useState<string|null>(null);
    const [lastGeneratedForUser, setLastGeneratedForUser] = useState<string|null>(null);

    let errorAlert;
    if (currentError) {
        errorAlert = (
            <div className="alert alert-danger alert-dismissible fade show" role="alert">
            {currentError.message}
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={(event) => {
                event.preventDefault();
                setCurrentError(null);
            }}></button>
            </div>
        )
    }

    let registerSpinner;
    if (generating) {
        registerSpinner = <span className="spinner-border spinner-border-sm" id="sign-in-spinner" role="status" aria-hidden="true"></span>;
    }

    let responseBanner;
    if (lastGeneratedToken?.length) {
        responseBanner = (
            <div className="alert alert-success alert-dismissible fade show" role="alert">
                Generated token for {lastGeneratedForUser} successfully!
                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={(event) => {
                    event.preventDefault();
                    setCurrentError(null);
                }}></button>
            </div>
        )
    }

    return (
        <div id="register">
            {currentError && errorAlert}
            <h5 className="mb-4">Generate Device Token</h5>
            <form className="mt-4">
                <div className="row mb-3">
                    <label htmlFor="inputRegistrationCode" className="col-sm-4 col-form-label">Username</label>
                    <div className="col-sm-8">
                        <input type="text" placeholder="john@example.com" className="form-control" id="inputUsername" />
                    </div>
                </div>
                <button type="submit" className="btn btn-secondary" onClick={(event) => {
                    event.preventDefault()

                    let username = (document.querySelector("#inputUsername") as HTMLInputElement).value
                    setGenerating(true);

                    PassiveAPI.generateDeviceToken(username).then((response) => {
                        setLastGeneratedToken(response.token);
                        setLastGeneratedForUser(username);
                    }).catch((error) => {
                        setCurrentError(error);
                    }).finally(() => {
                        setGenerating(false);
                    })
                }}>
                    {registerSpinner}
                    Register
                </button>
            </form>
            {responseBanner}
            <code>
                {lastGeneratedToken}
            </code>
        </div>
    )
}