import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { PassiveAPI } from "../services/PassiveAPI";

export default function Notifications() {

    let navigate = useNavigate();
    const [generating, setGenerating] = useState<boolean>(false);
    const [currentError, setCurrentError] = useState<Error|null>(null);
    const [lastGeneratedToken, setLastGeneratedToken] = useState<string|null>(null);
    const [lastGeneratedForUser, setLastGeneratedForUser] = useState<string|null>(null);

    let errorAlert;
    if (currentError) {
        errorAlert = (
            <div className="alert alert-danger alert-dismissible fade show" role="alert">
            {currentError.message}
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={(event) => {
                event.preventDefault();
                setCurrentError(null);
            }}></button>
            </div>
        )
    }

    let registerSpinner;
    if (generating) {
        registerSpinner = <span className="spinner-border spinner-border-sm" id="sign-in-spinner" role="status" aria-hidden="true"></span>;
    }

    let responseBanner;
    if (lastGeneratedToken?.length) {
        responseBanner = (
            <div className="alert alert-success alert-dismissible fade show" role="alert">
                Generated token for {lastGeneratedForUser} successfully!
                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={(event) => {
                    event.preventDefault();
                    setCurrentError(null);
                }}></button>
            </div>
        )
    }

    return (
        <div id="register">
            {currentError && errorAlert}
            <h5 className="mb-4">Notifications</h5>
            <p>Enabling notifications will let you get Quick Weigh notifications and reminders to manually weigh in.</p>
            <button className="btn btn-primary mb-1" onClick={async (event) => {
                event.preventDefault();
                console.log("register clicked");
                Notification.requestPermission().then((permission) => {
                    if (permission === 'granted') {
                        console.log("permission granted");
                        // register service worker
                        navigator.serviceWorker.register('/ServiceWorker.js').then((registration) => {
                            console.log('Service Worker registered with scope: ' + registration.scope);
                            PassiveAPI.setRegisteredForNotifications(true);
                        });
        
                        // subscribe
                        navigator.serviceWorker.ready.then((registration) => {
                            registration.pushManager.subscribe({
                                userVisibleOnly: true,
                                applicationServerKey: 'BJ21-YjHiwO-dFPFuNkw0Mo_PxR9VJeoCPVxn20DzrmOs_TAgX9WhIukAcxQcRwl0W3XowmwxBvnhKlqGGXrvGk'
                            }).then((subscription) => {
                                console.log("got subscription: " + JSON.stringify(subscription));
                                PassiveAPI.subscribeForNotifications(subscription).then((response) => {
                                    console.log("subscribed: " + JSON.stringify(response));
                                }).catch((error) => {
                                    console.error("error subscribing: " + error);
                                    setCurrentError(error);
                                });
                            }).catch((error) => {
                                console.error("error subscribing: " + error);
                            });
                        });
                    } else {
                        setCurrentError(new Error("You must grant permission to register for notifications."))
                        console.log("permission denied");
                    }
                });
            }}>
                Register for notifications {registerSpinner}
            </button>

            <button className="btn btn-danger d-block" onClick={async (event) => {
                event.preventDefault();
                navigator.serviceWorker.ready.then(function(serviceWorkerRegistration) {
                    // Access the push manager
                    serviceWorkerRegistration.pushManager.getSubscription().then(function(subscription) {
                        if (subscription) {
                            // If a subscription exists, unsubscribe
                            subscription.unsubscribe().then(function(successful) {
                                console.log('Unsubscription successful:', successful);
                            }).catch(function(error) {
                                console.error('Unsubscription failed:', error);
                            });
                        } else {
                            console.log('No active subscription found.');
                        }
                    }).catch(function(error) {
                        console.error('Error during getSubscription():', error);
                    });
                }).catch(function(error) {
                    console.error('Service worker registration error:', error);
                });
            }}>
                Unregister all notifications {registerSpinner}
            </button>
            
            {responseBanner}
            <code>
                {lastGeneratedToken}
            </code>
        </div>
    )
}