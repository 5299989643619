import React, { useEffect, useRef, useState } from 'react';

const ForegroundRefresh: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const backgroundTimeRef = useRef<number | null>(0);
    const reloadThreshold = 1000 * 60 * 5; // 5 minutes

    const handleVisibilityChange = () => {
        if (document.visibilityState === 'hidden') {
            backgroundTimeRef.current = Date.now();
        } else if (document.visibilityState === 'visible') {
            if (backgroundTimeRef.current && Date.now() - backgroundTimeRef.current > reloadThreshold) {
                window.location.reload();
            }

            backgroundTimeRef.current = null;
        }
    }

    useEffect(() => {
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        }
    }, []);

    return (
        <div>
            {children}
        </div>
    );
};

export default ForegroundRefresh;