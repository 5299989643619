import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { PassiveAPI } from "../services/PassiveAPI";

export default function Register() {

    let navigate = useNavigate();
    const [registering, setRegistering] = useState<boolean>(false);
    const [currentError, setCurrentError] = useState<Error|null>(null);

    if (PassiveAPI.isAuthenticated()) {
        return <Navigate to="/" />
    }

    let errorAlert;
    if (currentError) {
        errorAlert = (
            <div className="alert alert-danger alert-dismissible fade show" role="alert">
            {currentError.message}
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={(event) => {
                event.preventDefault();
                setCurrentError(null);
            }}></button>
            </div>
        )
    }

    let registerSpinner;
    if (registering) {
        registerSpinner = <span className="spinner-border spinner-border-sm" id="sign-in-spinner" role="status" aria-hidden="true"></span>;
    }

    return (
        <div id="register">
            {currentError && errorAlert}
            <h5 className="mb-4">Create Account</h5>
            <form className="mt-4">
            <div className="row mb-3">
                    <label htmlFor="inputRegistrationCode" className="col-sm-4 col-form-label">Registration Code</label>
                    <div className="col-sm-8">
                        <input type="text" placeholder="XXX-XXX-XXX" className="form-control" id="inputRegistrationCode" />
                    </div>
                </div>
                <div className="row mb-3">
                    <label htmlFor="inputUsername" className="col-sm-4 col-form-label">Username</label>
                    <div className="col-sm-8">
                        <input type="text" className="form-control" id="inputUsername" />
                    </div>
                </div>
                <div className="row mb-3">
                    <label htmlFor="inputFirstName" className="col-sm-4 col-form-label">First Name</label>
                    <div className="col-sm-8">
                        <input type="text" className="form-control" id="inputFirstName" />
                    </div>
                </div>
                <div className="row mb-3">
                    <label htmlFor="inputLastName" className="col-sm-4 col-form-label">Last Name</label>
                    <div className="col-sm-8">
                        <input type="text" className="form-control" id="inputLastName" />
                    </div>
                </div>
                <div className="row mb-3">
                    <label htmlFor="inputPassword" className="col-sm-4 col-form-label">Password</label>
                    <div className="col-sm-8">
                        <input type="password" className="form-control" id="inputPassword" />
                    </div>
                </div>
                <div className="row mb-3">
                    <label htmlFor="inputPasswordAgain" className="col-sm-4 col-form-label">Password (again)</label>
                    <div className="col-sm-8">
                        <input type="password" className="form-control" id="inputPasswordAgain" />
                    </div>
                </div>
                <button type="submit" className="btn btn-secondary" onClick={(event) => {
                    event.preventDefault()

                    let code = (document.querySelector("#inputRegistrationCode") as HTMLInputElement).value
                    let username = (document.querySelector("#inputUsername") as HTMLInputElement).value
                    let firstName = (document.querySelector("#inputFirstName") as HTMLInputElement).value
                    let lastName = (document.querySelector("#inputLastName") as HTMLInputElement).value
                    let password = (document.querySelector("#inputPassword") as HTMLInputElement).value
                    setRegistering(true);

                    PassiveAPI.register(code, username, firstName, lastName, password).then((authResponse) => {
                        navigate('/');
                    }).catch((error) => {
                        setCurrentError(error);
                    }).finally(() => {
                        setRegistering(false);
                    })
                }}>
                    {registerSpinner}
                    Register
                </button>
            </form>
        </div>
    )
}