import React, { Component, ReactElement, ReactNode } from 'react';
import { BrowserRouter, Link, Navigate, Route, Routes, NavLink } from 'react-router-dom';
import { JsxElement } from 'typescript';

import { PassiveAPI } from './services/PassiveAPI';

import PullToRefresh from 'pulltorefreshjs';
import ForegroundRefresh from './components/ForegroundRefresh';

import Home from './views/home';
import SignIn from './views/SignIn';
import SignOut from './views/SignOut';
import Register from './views/Register';
import DeviceTokens from './views/DeviceTokens';
import Notifications from './views/Notifications';

const standalone = window.matchMedia('(display-mode: standalone)').matches;

if (standalone) {
  PullToRefresh.init({
      onRefresh() {
          window.location.reload()
      },
  })
}

function NavigationView({ children }: { children: JSX.Element }) {

  let signOutItem;
  if (PassiveAPI.isAuthenticated()) {
    signOutItem = (
      <li className="nav-item">
        <NavLink to="/signout" className="nav-link link-secondary">Sign Out</NavLink>
      </li>
    )
  }

  let registerDeviceItem;
  if (PassiveAPI.canGenerateDeviceTokens()) {
    registerDeviceItem = (
      <li className="nav-item">
        <NavLink to="/device-tokens" className="nav-link link-secondary">Device Tokens</NavLink>
      </li>
    )
  }

  let notificationsItem = (
    <li className="nav-item">
      <NavLink to="/notifications" className="nav-link link-secondary">Notifications</NavLink>
    </li>
  )

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark bg-body-tertiary">
        <div className="container-fluid">
          {/* <a className="navbar-brand" href="#">Navbar</a> */}
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink to="/" className="nav-link link-secondary">Home</NavLink>
              </li>
              {registerDeviceItem}
              {notificationsItem}
              {signOutItem}
            </ul>
          </div>
          <span className="navbar-text">
              <i className="bi bi-file-person-fill"></i> {PassiveAPI.firstName()}
            </span>
        </div>
      </nav>
      {children}
    </div>
  )
}

function App() {

  return (
    <ForegroundRefresh>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<NavigationView><Home /></NavigationView>} />
          <Route path="/register" element={<NavigationView><Register /></NavigationView>} />
          <Route path="/device-tokens" element={<NavigationView><DeviceTokens /></NavigationView>} />
          <Route path="/notifications" element={<NavigationView><Notifications /></NavigationView>} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signout" element={<SignOut />} />
        </Routes>
      </BrowserRouter>
    </ForegroundRefresh>
  );
}

export default App;
