import { thisExpression } from '@babel/types';
import React, { MouseEvent, ReactElement } from 'react';
import { Navigate, NavigateFunction, useNavigate } from 'react-router-dom';
import { boolean } from 'yargs';

import { PassiveAPI } from '../services/PassiveAPI';

var signingIn: boolean = false

var navigate: NavigateFunction

const onSubmit = (e: MouseEvent<HTMLButtonElement>): void => {

}

export default function SignIn() {

    navigate = useNavigate();
    const [currentError, setCurrentError] = React.useState<Error|null>(null);
    const [signingIn, setSigningIn] = React.useState<boolean>(false);

    if (PassiveAPI.isAuthenticated()) {
        return <Navigate to="/" replace />
    }

    let buttonSpinner;
    if (signingIn) {
        buttonSpinner = <span className="visually-hidden spinner-border spinner-border-sm" id="sign-in-spinner" role="status" aria-hidden="true"></span>
    }

    let errorAlert;
    if (currentError) {
        errorAlert = (
            <div className="alert alert-danger alert-dismissible fade show" role="alert">
            {currentError.message}
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={(event) => {
                event.preventDefault();
                setCurrentError(null);
            }}></button>
            </div>
        )
    }

    let buttonText = <span id="sign-in-button-text">{signingIn ? <span>Signing in...</span> : <span>Sign in</span>}</span>

    return (
        <div id="sign-in-view">
            <div className="alert alert-warning" role="alert">
                Login required.
            </div>
            {currentError && errorAlert}
            <form>
                <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email</label>
                    <input type="email" className="form-control" id="email" aria-describedby="emailHelp" required />
                    <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
                    <div className="invalid-feedback">
                        Please enter a valid email address.
                    </div>
                </div>
                <div className="mb-3">
                    <label htmlFor="password" className="form-label">Password</label>
                    <input type="password" className="form-control" id="password" required />
                </div>
                <button className="btn btn-primary" id="signin" onClick={(event) => {
                        event.preventDefault();
                        setSigningIn(true)
                    
                        let usernameInput = document.querySelector("#email") as HTMLInputElement
                        let passwordInput = document.querySelector("#password") as HTMLInputElement
                    
                        let username = usernameInput.value
                        let password = passwordInput.value
                    
                        PassiveAPI.authenticate(username, password).then(error => {
                            console.log("authenticate promise resolved with value: " + error);
                    
                            if (error) {
                                setCurrentError(error)
                            } else {
                                console.log("authentication successful, navigating to /");
                                navigate('/');
                            }
                    
                            setSigningIn(false)
                        })
                }} disabled={signingIn}>
                    {buttonSpinner}
                    {buttonText}
                </button>
            </form>
        </div>
    )
}





// const { useState } = React;

// type SignInProps = {}

// type SignInState = {
//     signingIn: boolean;
//     currentError: Error | null;
// }

// class SignIn extends React.Component<SignInProps, SignInState> {

//     state: SignInState = {
//         signingIn: false,
//         currentError: null
//     }

//     private onSubmit = (e: MouseEvent<HTMLButtonElement>): void => {
//         e.preventDefault();
//         this.setState({signingIn: true});
    
//         let usernameInput = document.querySelector("#email") as HTMLInputElement
//         let passwordInput = document.querySelector("#password") as HTMLInputElement
    
//         let username = usernameInput.value
//         let password = passwordInput.value
    
//         PassiveAPI.authenticate(username, password).then(error => {
//             console.log("authenticate promise resolved with value: " + error);
    
//             if (error) {
//                 console.log("updating error state");
//                 this.setState({currentError: error})
//             } else {
//                 console.log("authentication successful, navigating to /");
//             }
    
//             this.setState({signingIn: false});
//         })
//     }

//     private clearError = (e: MouseEvent): void => {
//         this.setState({currentError: null})
//     }

//     render() {

//         const errorAlert = (
//             <div className="alert alert-danger alert-dismissible fade show" role="alert">
//             {this.state.currentError?.message}
//             <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={this.clearError}></button>
//             </div>
//         )

//         let buttonSpinner;
//         if (this.state.signingIn) {
//             buttonSpinner = <span className="visually-hidden spinner-border spinner-border-sm" id="sign-in-spinner" role="status" aria-hidden="true"></span>
//         }

//         let buttonText = <span id="sign-in-button-text">{this.state.signingIn ? <span>Signing in...</span> : <span>Sign in</span>}</span>

//         return (
//             <div id="sign-in">
//                 <div className="alert alert-warning" role="alert">
//                     Login required.
//                 </div>
//                 {this.state.currentError && errorAlert}
//                 <form>
//                     <div className="mb-3">
//                         <label htmlFor="email" className="form-label">Email</label>
//                         <input type="email" className="form-control" id="email" aria-describedby="emailHelp" required />
//                         <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
//                         <div className="invalid-feedback">
//                             Please enter a valid email address.
//                         </div>
//                     </div>
//                     <div className="mb-3">
//                         <label htmlFor="password" className="form-label">Password</label>
//                         <input type="password" className="form-control" id="password" required />
//                     </div>
//                     <button className="btn btn-primary" id="signin" onClick={this.onSubmit} disabled={this.state.signingIn}>
//                         {buttonSpinner}
//                         {buttonText}
//                     </button>
//                 </form>
//             </div>
//         )
//     }

// }

// export default SignIn;
