import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, useNavigate, Navigate } from 'react-router-dom';
import { Transition } from 'react-transition-group'; 
import { HistoryResponse, QuickWeighHistoryResponse, PassiveAPI, SessionHistoryResponse, DeviceInfoResponse } from '../services/PassiveAPI';
import moment from 'moment';
import { report } from 'process';

function deviceInfoSection(deviceInfoResponse: DeviceInfoResponse | null, fetchingDeviceInfo: boolean) {
    if (fetchingDeviceInfo) {
        return (
            <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        )
    }

    if (!deviceInfoResponse || deviceInfoResponse?.errorMessage) {
        return (
            <span>Error loading device info.</span>
        )
    }

    let lastSeen = moment(deviceInfoResponse.lastSeen).format('MM/DD/YY hh:mm A')

    return (
        <table className="table">
            <tbody>
                <tr>
                    <th scope="row">Serial Number</th>
                    <td>{deviceInfoResponse.serialNumber}</td>
                </tr>
                <tr>
                    <th scope="row">Last Seen</th>
                    <td>{lastSeen}</td>
                </tr>
                <tr>
                    <th scope="row">Software Version</th>
                    <td>{deviceInfoResponse.softwareVersion.version}</td>
                </tr>
            </tbody>
        </table>
    )
}

function historyTable(history: HistoryResponse | null, fetchingHistory: boolean) {
    if (fetchingHistory) {
        return (
            <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        )
    }

    if (!history || history?.errorMessage) {
        return (
            <span>Error loading history.</span>
        )
    }

    if (history.measurements.length < 1) {
        return (
            <div className="w-100 text-center">Nothing to see here.</div>
        )  
    }

    let rows = []
    for (let i = 0; i < history!.measurements.length; i++) {
        let prettyDate = moment(history!.measurements[i].date).format('MM/DD/YY hh:mm A')
        rows.push((
            <tr>
                <td>{prettyDate}</td>
                <td>{history!.measurements[i].weight.toString()}</td>
            </tr>
        ))
    }

    return (
        <table className="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Weight</th>
                </tr>
            </thead>
            <tbody>2
                {rows}
            </tbody>
        </table>
    )
}

function quickweighHistoryTable(history: QuickWeighHistoryResponse | null, fetchingHistory: boolean) {
    if (fetchingHistory) {
        return (
            <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        )
    }

    if (!history || history?.errorMessage) {
        return (
            <span>Error loading history.</span>
        )
    }

    if (history.quickWeighs.length < 1) {
        return (
            <div className="w-100 text-center">Nothing to see here.</div>
        )  
    }

    let rows = []
    for (let i = 0; i < history!.quickWeighs.length; i++) {
        let prettyDate = moment(history!.quickWeighs[i].date).format('MM/DD/YY hh:mm A')
        rows.push((
            <tr>
                <td>{prettyDate}</td>
                <td>{(Math.round(history!.quickWeighs[i].weight.valueOf() * 10) / 10).toString()}</td>
            </tr>
        ))
    }

    return (
        <table className="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Weight</th>
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </table>
    )
}

function sessionsTable(sessionHistory: SessionHistoryResponse | null, fetchingHistory: boolean) {
    if (fetchingHistory) {
        return (
            <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        )
    }

    if (!sessionHistory || sessionHistory?.errorMessage) {
        return (
            <span>Error loading history.</span>
        )
    }

    if (sessionHistory.sessions.length < 1) {
        return (
            <div className="w-100 text-center">No sessions recorded yet.</div>
        )  
    }

    let rows = []
    for (let i = 0; i < sessionHistory!.sessions.length; i++) {
        let prettyDate = moment(sessionHistory!.sessions[i].startTime).format('MM/DD/YY hh:mm A')
        rows.push((
            <tr>
                <td>{prettyDate}</td>
                <td>{sessionHistory!.sessions[i].duration.valueOf()} sec</td>
                <td>{sessionHistory!.sessions[i].size.valueOf() / 1000} kB</td>
            </tr>
        ))
    }

    return (
        <table className="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Duration</th>
                    <th scope="col">Size</th>
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </table>
    )
}

export default function Home() {
    let navigate = useNavigate();

    const [fetchingDeviceInfo, setFetchingDeviceInfo] = useState<boolean>(true);
    const [deviceInfo, setDeviceInfo] = useState<DeviceInfoResponse|null>(null);

    const [fetchingHistory, setFetchingHistory] = useState<boolean>(true);
    const [history, setHistory] = useState<HistoryResponse|null>(null);

    const [fetchingSessionHistory, setFetchingSessionHistory] = useState<boolean>(true);
    const [sessionHistory, setSessionHistory] = useState<SessionHistoryResponse|null>(null);

    const [fetchingQuickWeighHistory, setFetchingQuickWeighHistory] = useState<boolean>(true);
    const [quickWeighHistory, setQuickWeighHistory] = useState<QuickWeighHistoryResponse|null>(null);

    const [reportingWeight, setReportingWeight] = useState<boolean>(false);
    const [lastReportTime, setLastReportTime] = useState<Date>(new Date());

    const [currentError, setCurrentError] = useState<Error|null>(null);

    useEffect(() => {
        PassiveAPI.myDeviceInfo().then((response: DeviceInfoResponse) => {
            console.log("got device info response: " + JSON.stringify(response))
            setFetchingDeviceInfo(false);
            setDeviceInfo(response);
        }, (error: Error) => {
            console.error("error getting device info: " + error)
            setFetchingDeviceInfo(false);
        })
    }, [])

    useEffect(() => {
        PassiveAPI.history().then((response: HistoryResponse) => {
            console.log("got history response: " + JSON.stringify(response))
            setFetchingHistory(false);
            setHistory(response);
        }, (error: Error) => {
            console.error("error getting history: " + error)
            setFetchingHistory(false);
        })
    }, [lastReportTime])

    useEffect(() => {
        PassiveAPI.sessionHistory().then((response: SessionHistoryResponse) => {
            console.log("got session history response: " + JSON.stringify(response))
            setFetchingSessionHistory(false);
            setSessionHistory(response);
        }, (error: Error) => {
            console.error("error getting session history: " + error)
            setFetchingSessionHistory(false);
        })
    }, [])

    useEffect(() => {
        PassiveAPI.quickWeighHistory().then((response: QuickWeighHistoryResponse) => {
            console.log("got quick weigh history response: " + JSON.stringify(response))
            setFetchingQuickWeighHistory(false);
            setQuickWeighHistory(response);
        }, (error: Error) => {
            console.error("error getting quick weigh history: " + error)
            setFetchingQuickWeighHistory(false);
        })
    }, [])

    if (!PassiveAPI.isAuthenticated()) {
        return <Navigate to="/signin" replace />
    }

    let reportSpinner;
    if (reportingWeight) {
        reportSpinner = <span className="spinner-border spinner-border-sm" id="sign-in-spinner" role="status" aria-hidden="true"></span>;
    }

    let errorAlert;
    if (currentError) {
        errorAlert = (
            <Transition in={false} timeout={200} classNames="my-node">
            <div className="alert alert-danger alert-dismissible fade show" role="alert">
            {currentError.message}
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={(event) => {
                event.preventDefault();
                setCurrentError(null);
            }}></button>
            </div>
            </Transition>
        )
    }

    return (
        <div id="home">
            {currentError && errorAlert}

            {/* <div className="input-group mb-3">
                <div id="report-weight" className="p-3 border bg-light w-100 align-items-center">
                    <h6>Report Weight</h6>
                    <form className="row row-cols-lg-auto g-2">
                        <div className="col-8">
                            <label className="visually-hidden" htmlFor="inlineFormInputGroupUsername">Weight</label>
                            <div className="input-group">
                                <input disabled={reportingWeight} type="number" step="0.1" className="form-control" id="weight-input" placeholder="Weight (e.g. 120.8)" />
                                <div className="input-group-text">lbs</div>
                            </div>
                        </div>
                        <div className="col-3">
                            <button disabled={reportingWeight} type="submit" className="btn btn-dark" onClick={(event) => {
                                event.preventDefault()

                                let weight = document.querySelector("#weight-input") as HTMLInputElement
                                let parts = weight.value.split('.')
                                if (parts.length != 2 || parts[1].length != 1) {
                                    setCurrentError(new Error("Weight must be reported in increments of a 10th of a pound."));
                                    return;
                                }

                                setReportingWeight(true);
                                PassiveAPI.reportWeight(new Number(weight.value)).then(response => {
                                    setLastReportTime(new Date());
                                }).catch(error => {
                                    console.error("error reporting weight" + error);
                                }).finally(() => {
                                    setReportingWeight(false);
                                    weight.value = "";
                                })
                            }}>
                                {reportSpinner}
                                Report
                            </button>
                        </div>
                    </form>
                </div>
            </div> */}
            <div className="mb-3">
                <div id="device-info" className="p-3 border bg-light w-100">
                    <h6><strong>Your Scale</strong></h6>
                    {deviceInfoSection(deviceInfo, fetchingDeviceInfo)}
                </div>
            </div>
            <div className="mb-3">
                <div id="history" className="p-3 border bg-light w-100">
                    <h6><strong>Recent Quick Weigh</strong></h6>
                    {quickweighHistoryTable(quickWeighHistory, fetchingQuickWeighHistory)}
                </div>
            </div>
            {/* <div className="mb-3">
                <div id="history" className="p-3 border bg-light w-100">
                    <h6><strong>Recent Mannual Weighs</strong></h6>
                    {historyTable(history, fetchingHistory)}
                </div>
            </div> */}
            <div className="mb-3">
                <div id="sessions" className="p-3 border bg-light w-100">
                    <h6><strong>Recent Passive Sessions</strong></h6>
                    {sessionsTable(sessionHistory, fetchingSessionHistory)}
                </div>
            </div>
        </div>
    )
}
